import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //     path: '*',
    //     component: function () { 
    //       return import('./views/Home.vue')
    //    }
    // },
    { path: '*', redirect: '/' },
    {
        path: '/',
        name: 'home',
        component: function () { 
          return import('./views/Home.vue')
       }
    },
    // ------------ OFFICE
    {
        path: '/muebles-de-oficina',
        name: 'office-furniture',
        component: function () { 
            return import('./views/Office/OfficeFurniture.vue')
        }
    },
    // Sillas
    {
      path: '/muebles-de-oficina/sillas-para-oficinas',
      name: 'office-chairs',
      component: function () { 
          return import('./views/Office/OfficeChairs.vue')
      }
    },
    {
      path: '/muebles-de-oficina/sillas-para-oficinas/:tag',
      name: 'office-chairs-tag',
      component: function () { 
          return import('./views/Office/OfficeChairs.vue')
      }
    },
    {
      path: '/muebles-de-oficina/muebles-para-recepcion',
      name: 'furniture-reception',
      component: function () { 
          return import('./views/Office/FurnitureReception.vue')
      }
    },
    {
      path: '/muebles-de-oficina/salas-de-espera',
      name: 'waiting-room',
      component: function () { 
          return import('./views/Office/WaitingRooms.vue')
      }
    },
    {
      path: '/muebles-de-oficina/espacios-colaborativos',
      name: 'collaborative-spaces',
      component: function () { 
          return import('./views/Office/CollaborativeSpaces.vue')
      }
    },
    {
      path: '/muebles-de-oficina/salas-de-juntas',
      name: 'meeting-rooms',
      component: function () { 
          return import('./views/Office/MeetingRooms.vue')
      }
    },
    {
      path: '/muebles-de-oficina/divisiones-de-oficina',
      name: 'office-divisions',
      component: function () { 
          return import('./views/Office/OfficeDivisions.vue')
      }
    },
    {
      path: '/muebles-de-oficina/muebles-operativos',
      name: 'operative-furniture',
      component: function () { 
          return import('./views/Office/OperativeFurniture.vue')
      }
    },
    {
      path: '/muebles-de-oficina/muebles-gerenciales',
      name: 'managerial-furniture',
      component: function () { 
          return import('./views/Office/ManagerialFurniture.vue')
      }
    },
    // ------------ METAL SHELVES
    {
      path: '/estanteria-metalica',
      name: 'metal-shelves',
      component: function () { 
          return import('./views/Shelves/MetalShelves.vue')
      }
    },
    // -- Heavy shelves
    {
      path: '/estanteria-metalica/estanteria-pesada',
      name: 'heavy-shelves',
      component: function () { 
          return import('./views/Shelves/HeavyShelves.vue')
      }
    },
    // Rack selectivo
    {
      path: '/estanteria-metalica/estanteria-pesada/:tag',
      name: 'heavy-shelves-tag',
      component: function () { 
          return import('./views/Shelves/HeavyShelves.vue')
      }
    },
    // Rack picking
    {
      path: '/estanteria-metalica/rack-picking-carga-manual',
      name: 'rack-picking',
      component: function () { 
          return import('./views/Shelves/RackPicking.vue')
      }
    },
    {
      path: '/estanteria-metalica/mezzanines-y-entrepisos',
      name: 'mezzanines',
      component: function () { 
          return import('./views/Shelves/Mezzanines.vue')
      }
    },
    // -- Medium shelves
    {
      path: '/estanteria-metalica/estanteria-mediana',
      name: 'medium-shelves',
      component: function () { 
          return import('./views/Shelves/MediumShelves.vue')
      }
    },
    {
      path: '/estanteria-metalica/estanteria-mediana/:tag',
      name: 'medium-shelves-tag',
      component: function () { 
          return import('./views/Shelves/MediumShelves.vue')
      }
    },
    // Light shelves
    {
      path: '/estanteria-metalica/estanteria-liviana',
      name: 'light-shelves',
      component: function () { 
          return import('./views/Shelves/LightShelves.vue')
      }
    },    
    // Mesh shelves
    {
      path: '/estanteria-metalica/entrepanos-de-maya',
      name: 'mesh-shelves',
      component: function () { 
          return import('./views/Shelves/MeshShelves.vue')
      }
    },    
    // Complementaries products
    {
      path: '/estanteria-metalica/productos-complementarios',
      name: 'complementaries-products',
      component: function () { 
          return import('./views/Shelves/ComplementariesProducts.vue')
      }
    }, 
    {
      path: '/estanteria-metalica/productos-complementarios/:tag',
      name: 'complementaries-products-tag',
      component: function () { 
          return import('./views/Shelves/ComplementariesProducts.vue')
      }
    },


    // ------------ SUPERMERCADO
    {
      path: '/muebles-de-supermercado',
      name: 'supermarket-furniture',
      component: function () { 
          return import('./views/Supermarket/SupermarketFurniture.vue')
      }
    },
    // Gondolas
    {
      path: '/muebles-de-supermercado/gondolas',
      name: 'gondolas',
      component: function () { 
          return import('./views/Supermarket/Gondolas.vue')
      }
    },
    {
      path: '/muebles-de-supermercado/gondolas/:tag',
      name: 'gondolas-tag',
      component: function () { 
          return import('./views/Supermarket/Gondolas.vue')
      }
    },
    // Archivadores
    {
      path: '/muebles-de-supermercado/archivadores',
      name: 'cabinets',
      component: function () { 
          return import('./views/Supermarket/Cabinets.vue')
      }
    },
    {
      path: '/muebles-de-supermercado/archivadores/:tag',
      name: 'cabinets-tag',
      component: function () { 
          return import('./views/Supermarket/Cabinets.vue')
      }
    },
    // Lockers
    {
      path: '/muebles-de-supermercado/lockers',
      name: 'lockers',
      component: function () { 
          return import('./views/Supermarket/Lockers.vue')
      }
    },
    {
      path: '/muebles-de-supermercado/lockers/:tag',
      name: 'lockers-tag',
      component: function () { 
          return import('./views/Supermarket/Lockers.vue')
      }
    },
    // Muebles especiales
    {
      path: '/muebles-de-supermercado/muebles-especiales',
      name: 'special-furniture',
      component: function () { 
          return import('./views/Supermarket/SpecialFurniture.vue')
      }
    },
    {
      path: '/muebles-de-supermercado/muebles-especiales/:tag',
      name: 'special-furniture-tag',
      component: function () { 
          return import('./views/Supermarket/SpecialFurniture.vue')
      }
    },




    // ----- NOSOTROS
    // Ofertas
    {
      path: '/ofertas',
      name: 'offers',
      component: function () { 
          return import('./views/Us/Offers.vue')
      }
    }, 
    // Servicios
    {
      path: '/servicios',
      name: 'services',
      component: function () { 
          return import('./views/Us/Services.vue')
      }
    },     
    {
      path: '/about',
      name: 'about',
      component: function () { 
          return import('./views/About.vue')
      }
    },
    // CONTACTO
    {
      path: '/contacto',
      name: 'contact',
      component: function () { 
          return import('./views/Contact.vue')
      }
    },
    // GENERICS
    {
      path: '/registro-exitoso',
      name: 'success-page',
      component: function () { 
          return import('./views/Generics/SuccessPage.vue')
      }
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
//   scrollBehavior() {
//     document.getElementById('app').scrollIntoView();
//  } 
})


export default router