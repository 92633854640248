<template>
    <div class="content-main-menu-desktop">
        <div class="main-menu" ref="main_menu">
            <router-link tag="a" to="/">
                <img src="@/assets/logo_p.svg" title="Ir al Inicio | Industrias Pico" class="logo" alt="Industrias Pico">
            </router-link>
            <div class="container-two-lines">
                <ul class="line-top">
                    <li style="display: none">
                        <router-link tag="a" to="/">
                            Inicio
                        </router-link>
                    </li>
                    <!-- Muebles de Oficina -->
                    <li class="drop-item">
                        <div class="li-expand">
                            <router-link tag="span" :to="{ name: 'office-furniture' }">
                                Muebles de Oficina
                            </router-link>
                            <span class="material-icons expand-more">expand_more</span>
                        </div>

                        <!-- DROP MENU -->
                        <ul ref="drop_menu_1" class="drop-menu">
                            <div class="drop-menu-content">
                                <!-- <li>
                                    <router-link tag="a" :to="{ name: 'interior-architecture' }">
                                        Arquitectura interior
                                    </router-link>
                                </li> -->
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link tag="a" :to="{ name: 'office-chairs' }">
                                            Sillas para oficinas
                                        </router-link>
                                        <span class="material-icons expand-more">chevron_right</span>
                                    </div>

                                    <!-- DROP MENU -->
                                    <div ref="drop_menu_1_1" class="drop-menu drop-menu-left">
                                        <div class="drop-menu-content">
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-presidenciales' }">
                                                    Sillas Presidenciales
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-gerenciales' }">
                                                    Sillas Gerenciales
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-operativas' }">
                                                    Sillas Operativas
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-interlocutoras' }">
                                                    Sillas Interlocutoras
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-para-colectivos' }">
                                                    Sillas para Colectivos
                                                </router-link>
                                            </li>
                                        </div>
                                    </div>
                                </li>
                                <li><router-link tag="a" :to="{ name: 'furniture-reception' }">Muebles para Recepción</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'waiting-room' }">Salas de Espera</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'collaborative-spaces' }">Espacios Colaborativos</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'meeting-rooms' }">Salas de Juntas</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'office-divisions' }">Divisiones de Oficina</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'operative-furniture' }">Muebles Operativos</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'managerial-furniture' }">Muebles Gerenciales</router-link></li>
                            </div>
                        </ul>
                    </li>
                    <!-- Estanterías metálicas -->
                    <li class="drop-item">
                        <div class="li-expand">
                            <router-link tag="span" :to="{ name: 'metal-shelves' }">
                                Estanterías Metálicas
                            </router-link>
                            <span class="material-icons expand-more">expand_more</span>
                        </div>
                        <!-- DROP MENU -->
                        <div ref="drop_menu_2" class="drop-menu">
                            <div class="drop-menu-content">
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link tag="span" :to="{ name: 'heavy-shelves' }">
                                            Estantería Pesada
                                        </router-link>
                                        <span class="material-icons expand-more">chevron_right</span>
                                    </div>
                                    <!-- DROP MENU -->
                                    <div ref="drop_menu_2_1" class="drop-menu drop-menu-left">
                                        <div class="drop-menu-content">
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/rack-selectivo' }">
                                                    Rack Selectivo
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/carga-compacta-drive-in' }">
                                                    Carga Compacta Drive In
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/carga-larga-o-cantilever' }">
                                                    Carga Larga o Cantilever
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/carga-con-estibas' }">
                                                    Carga con estibas
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/carretes-o-porta-bobinas' }">
                                                    Carretes o Porta-Bobinas
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/dinamicas-o-de-flujo' }">
                                                    Dinámicas o de flujo
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/autoportantes-y-de-campo' }">
                                                    Autoportantes y de Campo
                                                </router-link>
                                            </li>
                                        </div>
                                    </div>
                                </li>
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link tag="span" :to="{ name: 'medium-shelves' }">
                                            Estantería Mediana
                                        </router-link>
                                        <span class="material-icons expand-more">chevron_right</span>
                                    </div>
                                    <!-- DROP MENU -->
                                    <div ref="drop_menu_2_2" class="drop-menu drop-menu-left">
                                        <div class="drop-menu-content">
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-mediana/estanteria-trial' }">
                                                    Trial 200Kg/Nivel
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/estanteria-mediana/estanteria-minirack' }">
                                                    MiniRack 50 300-500Kg/Nivel
                                                </router-link>
                                            </li>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'light-shelves' }">
                                        Estanterías livianas
                                    </router-link>
                                </li>
                                <li><router-link tag="a" :to="{ name: 'rack-picking' }">Rack Picking - Carga Manual</router-link></li>
                                <li><router-link tag="a" :to="{ name: 'mezzanines' }">Mezzanines y Entrepisos</router-link></li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'mesh-shelves' }">
                                        Entrepaños de malla
                                    </router-link>
                                </li>
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link tag="span" :to="{ name: 'complementaries-products' }">
                                            Complementarios
                                        </router-link>
                                        <span class="material-icons expand-more">chevron_right</span>
                                    </div>
                                    <!-- DROP MENU -->
                                    <div ref="drop_menu_2_3" class="drop-menu drop-menu-left">
                                        <div class="drop-menu-content">
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/caja-movil-con-divisores' }">
                                                    Caja Móvil con Divisores
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/divisiones-moviles-para-estanteria' }">
                                                    Divisiones Móviles
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/muebles-gaveteros-cajas-metalicas' }">
                                                    Muebles Gaveteros
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/contenedores-apilables-y-de-malla' }">
                                                    Contenedores Apilables y de Malla
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/barreras-y-protectores-de-marco' }">
                                                    Barreras y Protectores de Marco
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/plataformas-para-picking-con-montacargas-convencional' }">
                                                    Plataformas para Picking
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/carros-multiusos-y-picking' }">
                                                    Carros Multiusos y Picking
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/estibas-metalicas' }">
                                                    Estibas Metalicas
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/estanteria-metalica/productos-complementarios/unidades-modulares-de-trabajo' }">
                                                    Unidades Modulares de Trabajo
                                                </router-link>
                                            </li>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </div>
                    </li>
                    <!-- Mobiliario de supermercado -->
                    <li class="drop-item">
                        <div class="li-expand">
                            <router-link tag="span" :to="{ name: 'supermarket-furniture' }">
                                Supermercado
                            </router-link>
                            <span class="material-icons expand-more">expand_more</span>
                        </div>
                        <!-- DROP MENU -->
                        <div ref="drop_menu_3" class="drop-menu">
                            <div class="drop-menu-content">
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link tag="a" :to="{ name: 'gondolas' }">
                                            Góndolas
                                        </router-link>
                                        <span class="material-icons expand-more">chevron_right</span>
                                    </div>

                                    <!-- DROP MENU -->
                                    <div ref="drop_menu_3_1" class="drop-menu drop-menu-left">
                                        <div class="drop-menu-content">
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/isla' }">
                                                    Isla
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/lateral' }">
                                                    Lateral
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/verdulero' }">
                                                    Verdulero
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/accesorios' }">
                                                    Accesorios
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/canastilla' }">
                                                    Canastilla
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/escalonada' }">
                                                    Escalonada
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/punto-de-pago' }">
                                                    Punto de pago
                                                </router-link>
                                            </li>
                                        </div>
                                    </div>
                                </li>
                                <!-- ////// -->
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link tag="a" :to="{ name: 'cabinets' }">
                                            Archivadores
                                        </router-link>
                                        <span class="material-icons expand-more">chevron_right</span>
                                    </div>

                                    <!-- DROP MENU -->
                                    <div ref="drop_menu_3_2" class="drop-menu drop-menu-left">
                                        <div class="drop-menu-content">
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/archivadores/gaveta' }">
                                                    Gaveta
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/archivadores/aereo' }">
                                                    Aéreo
                                                </router-link>
                                            </li>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'lockers' }">
                                        Lockers
                                    </router-link>
                                </li>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="line-bottom">
                <!-- ////// -->
                    <li class="drop-item">
                        <div class="li-expand">
                            <router-link tag="a" :to="{ name: 'special-furniture' }">
                                Muebles especiales
                            </router-link>
                            <span class="material-icons expand-more">expand_more</span>
                        </div>
                        <!-- DROP MENU -->
                        <div ref="drop_menu_3_2" class="drop-menu drop-menu-left">
                            <div class="drop-menu-content">
                                <li>
                                    <router-link tag="a" 
                                        :to="{ path: '/muebles-de-supermercado/muebles-especiales/bins-de-ofertas' }">
                                        Bins de Ofertas
                                    </router-link>
                                </li>
                                <li>
                                    <router-link tag="a" 
                                        :to="{ path: '/muebles-de-supermercado/muebles-especiales/canastilla-para-liquidacion' }">
                                        Canastilla para Liquidación
                                    </router-link>
                                </li>
                            </div>
                        </div>
                    </li>
                    <li>
                        <router-link tag="a" :to="{ name: 'offers' }">
                            Ofertas
                        </router-link>
                    </li>
                    <li>
                        <router-link 
                            tag="a" 
                            :to="{ name: 'contact' }">
                            Contáctenos
                        </router-link>
                    </li>
                    <li class="drop-item">
                        <div class="li-expand">
                            Nosotros
                            <span class="material-icons expand-more">expand_more</span>
                        </div>
                        <!-- DROP MENU -->
                        <div ref="drop_menu_4" class="drop-menu">
                            <div class="drop-menu-content">
                                <li>
                                    <router-link tag="a" :to="{ name: 'services' }">
                                        Servicios
                                    </router-link>
                                </li>
                                <!-- <li>
                                    <router-link tag="a" :to="{ name: 'projects' }">
                                        Proyectos
                                    </router-link>
                                </li> -->
                                <li>Blog</li>
                            </div>
                        </div>
                    </li> 
                </ul>
            </div>
        </div>

         <!-- <div class="logo-icon-menu">
             <router-link class="cursor-pointer" tag="div" to="/">
                <img src="@/assets/logo-industrias-pico.png" class="logo" alt="Industrias pico">
             </router-link>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },  
    computed: {
        route() {
            return this.$router.app._route.name;
        }
    },
    watch: {
        route() {
            if (this.route != 'home') {
                this.$refs.main_menu.classList.add('main-menu-withlogo');
            } else {
                this.$refs.main_menu.classList.remove('main-menu-withlogo');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.content-main-menu-desktop {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 5px 15px 15px;
    position: relative;
    z-index: 99;
    max-width: 800px;

    @media (max-width: 1000px) {
        display: none
    }

    .main-menu-withlogo {
        display: flex !important;
        align-items: center !important;

        .logo {
            display: block !important;
            margin-right: 15px
        }

        @media (min-width: 1280px) {
            .logo { margin-right: 35px !important }
        }
    }
    .main-menu {  
        // width: 75%;
       .logo {
            width: 60px;
            margin-bottom: 5px;
            margin-right: 15px;
            display: none
        }
        .line-top {
            position: relative;
            margin-bottom: -10px;
            z-index: 10;
        }
        // .line-bottom { border: 1px solid black; z-index: 15 }
        .item-menu-mr-25 { margin-right: 25px !important }
        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0px;
            margin-bottom: 0px;
            // border: 1px solid green;

            li { 
                font-size: 13px;
                padding: 10px 15px 10px 0px;
                margin-right: 25px;
                border-bottom: 1px solid transparent;
                cursor: pointer;
                position: relative;

                a { 
                    color: #000000;
                    text-decoration: none
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 1px;
                    background-color: $main-red-1;
                    width: 0px;
                    @include transition(all 0.5s);
                }

                &:hover {
                    &:before { width: 100% }
                }
            }

            .drop-item {
                &:hover {
                    .drop-menu { display: block }
                }
                .li-expand {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 2;

                    .expand-more { font-size: 15px; margin-left: 3px; color: $main-red-1 }
                }

                .drop-menu {
                    display: none;
                    position: absolute;

                    .drop-item {
                        &:hover {
                            .drop-menu-left { display: block }
                        }
                    }
                    .drop-menu-content {
                        background-color: $main-red-1;
                        color: #FFFFFF;
                        padding: 10px 25px 10px 25px;
                        margin-top: 15px;
                        width: 280px;
                        border-radius: 2px;

                        li { 
                            margin-right: 0px;
                            @include transition(margin-left 0.5s);
                            a { color: #FFFFFF }

                            &:hover {  margin-left: 15px }
                            @media (min-width: 1900px) {
                                padding: 10px 0px 10px 0px
                            }
                        }
  
                        .li-expand { 
                            justify-content: flex-start;
                            .expand-more { 
                                color: #FFFFFF;
                                margin-left: 20px
                            }
                        }

                        .drop-menu-left {
                            display: none;
                            top: 0;
                            .drop-menu-content { margin-left: 250px }
                        }
                    }
                }
            }
        }

        @media (min-width: 1900px) {
            ul {
                li { font-size: 15px }
            }
        }
    }

    .logo-icon-menu {
        width: 20%;
        text-align: right;
        .logo { width: 150px }
    }

    @media (min-width: 1200px) {
        max-width: 750px;
    }
    @media (min-width: 1280px) {
        padding: 25px 8px 20px 28px;
        max-width: 850px
    }
    @media (min-width: 1900px) {
        padding: 25px 8px 20px 28px;
        max-width: 1000px;
        .logo-icon-menu { border: 1px solid red }
    }
}
</style>

