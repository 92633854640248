<template>
    <div class="store-products">
        <!-- <generic-bg-color></generic-bg-color> -->
        <div
            v-if="data_products.is_offers_tab == true" 
            class="container container-indu title-page-offer">
            <div class="first">
                <img src="images/ofertas/star.svg" class="star" alt="star">
                <h1>SUPER OFERTAS DEL MES</h1>
            </div>
            <h4>Fabricantes e Importadores de muebles y sillas para oficinas</h4>
        </div>

       <div
            v-else
            class="container container-indu title-page">
            <h1 v-html="data_products.title_page">
                <!-- // -->
            </h1>
        </div>

        <div class="container container-indu container-products">   
            <!-- Left bar -->
            <div class="left-bar">
                <!-- Options -->
                <div class="options">
                    <!-- Section -->
                    <div class="section">
                        <h5 class="title">Tipo</h5>
                        <ul>
                            <li v-for="(type, index) in data_products.types" :key="index">
                                <label 
                                    :for="`check_${index}`" 
                                    class="label-check">
                                    <input 
                                        type="checkbox" 
                                        :id="`check_${index}`" 
                                        class="input-check"
                                        :value="type.value"
                                        v-model="selected_types">
                                    <div class="box-check"></div>
                                    <p class="m-0">{{ type.text }}</p>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Contact all -->
                <div
                    v-if="data_products.is_offers_tab == false || !data_products.is_offers_tab" 
                    class="contact">
                    <div class="data-contact">
                        <h1>CONTÁCTANOS</h1>
                        <ul>
                            <li><a href="tel:3182917056">317 648 84 98</a> / </li>
                            <li><a href="tel:3204032099">320 4032 099</a></li>
                            <li>
                                <a href="https://www.google.com/maps/place/Cra.+19+%2317-35,+Bucaramanga,+Santander/@7.130643,-73.1266664,3a,75y,90t/data=!3m5!1e1!3m3!1s*211m3*211y10261475366423041553*212y15340164861115571174*213m0*212m2*211x71305978*212x3563700637*213m2*211y10261475370038639255*212y15592877205208482114*215m2*211x71305978*212x3563700637!2e0!6s%2F%2Fgeo3.ggpht.com%2Fmaps%2Fphotothumb%2Ffd%2Fv1%3Fbpb%3DChAKDnNlYXJjaC5UQUNUSUxFEmUKNwkRFhFnehVojhHmG7leqjbj1BojCxDThbhCGhoSGAoUChIJERYRZ3oVaI4R-lIX9GlWwe0QIwwSCg36CkAEFZ3BadQaEgmXupI-exVojhFCwbwaNAdl2CoKDfoKQAQVncFp1BoECFYQVg%26gl%3DCO!4m5!3m4!1s0x8e68157a67111611:0xd4e336aa5eb91be6!8m2!3d7.1305978!4d-73.1266659" title="Ubicación - Google Maps" target="_blank" class="location">
                                    <span class="icon-location"></span>Carrera 19 No. 17 - 35 Bucaramanga, Santander
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="text-center">
                        <router-link class="contact-button" tag="button" :to="{ name: 'contact' }">
                            Solicitar cotización
                        </router-link>
                    </div>
                </div>
               <!-- Contact OFFERS Esta columna solo se muestra cuando es la pestaña de ofertas -->
                <div
                    v-if="data_products.is_offers_tab == true" 
                    class="contact_offers">
                    <a href="https://industriaspico.com/">
                        <img src="@/assets/images/ofertas/warranty/logo-pico-mobile.png" width="80%" class="logo" alt="Logo">
                    </a>
                    <div class="contact-title">
                        <h1>OFERTAS <span>ESPECIALES</span></h1>
                        <p>Transporte Incluido a las ciudades principales del país. *Aplican restricciones.</p>
                    </div>
                    <div class="warranty">
                        <img src="@/assets/images/ofertas/warranty/garantia.svg" class="image-warranty" alt="Garantia">
                        <h1>Despachos a <span>Todo el País</span></h1>
                        <p><span>Oferta limitada</span>, hasta <span>agotar existencias.</span></p>

                        <img src="@/assets/images/ofertas/warranty/payu.png" class="payu" alt="PAYU">
                    </div>
                    <div class="data-contact">
                        <h1>O llama Ahora</h1>
                        <ul>
                            <li><a href="tel:3182917056">317 648 84 98</a> / </li>
                            <li><a href="tel:3204032099">320 4032 099</a></li>
                            <li>
                                <a href="https://www.google.com/maps/place/Cra.+19+%2317-35,+Bucaramanga,+Santander/@7.130643,-73.1266664,3a,75y,90t/data=!3m5!1e1!3m3!1s*211m3*211y10261475366423041553*212y15340164861115571174*213m0*212m2*211x71305978*212x3563700637*213m2*211y10261475370038639255*212y15592877205208482114*215m2*211x71305978*212x3563700637!2e0!6s%2F%2Fgeo3.ggpht.com%2Fmaps%2Fphotothumb%2Ffd%2Fv1%3Fbpb%3DChAKDnNlYXJjaC5UQUNUSUxFEmUKNwkRFhFnehVojhHmG7leqjbj1BojCxDThbhCGhoSGAoUChIJERYRZ3oVaI4R-lIX9GlWwe0QIwwSCg36CkAEFZ3BadQaEgmXupI-exVojhFCwbwaNAdl2CoKDfoKQAQVncFp1BoECFYQVg%26gl%3DCO!4m5!3m4!1s0x8e68157a67111611:0xd4e336aa5eb91be6!8m2!3d7.1305978!4d-73.1266659" title="Ubicación - Google Maps" target="_blank" class="location">
                                    <span class="icon-location"></span>Carrera 19 No. 17 - 35 Bucaramanga, Santander
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="products">
                <h4 
                    v-if="filter_products.length == 0" 
                    class="mb-5">
                    No hay productos disponibles
                </h4>
                <!-- Item product -->
                <div
                    v-for="(product, index) in filter_products" :key="index"
                    @click="toggleProductModal(product)" 
                    class="item-product"
                    :class="{ 'item-product-offer': data_products.is_offers_tab }">
                    <!-- :data-aos-delay="`${((index + 1) * 100)}`" -->
                 
                    <img :src="require(`@/assets/images/${product.url_image}`)" width="100%" class="image" :alt="product.name">
                    <h1 class="title">{{ product.name }}</h1>
                    <h5 
                        v-if="product.price"
                        class="price">$ {{ product.price }}</h5>
                </div>
            </div>
        </div>

        <!-- Modal show product -->
        <!-- Button trigger modal -->
        <button type="button" 
            ref="btn_show_product_modal"
            class="btn-full-hidden" 
            data-toggle="modal" 
            data-target="#show_product_modal">
            Launch demo modal
        </button>

        <!-- Modal -->
        <div class="modal fade" id="show_product_modal" tabindex="-1" role="dialog" aria-labelledby="show_product_modalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <!-- <div
                            v-if="modal_product.url_image"
                            :style="`background-image: url(${require(`@/assets/images/${modal_product.url_image}`)})`" 
                            class="bg-image"></div> -->
<!-- 
                        <img
                            v-if="modal_product.url_image" 
                            :src="require(`@/assets/images/${modal_product.url_image}`)" width="100%" class="image" alt="Producto"> -->
                        <div id="slider_modal" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators" style="margin-bottom: 30px">
                                <!-- <li data-target="#slider_modal" data-slide-to="0" class="active"></li> -->
                                <!-- <li data-target="#slider_modal" data-slide-to="1"></li> -->
                                <li v-for="(slide, index) in modal_product.slides" 
                                    :key="index"
                                    :class="{ active: index == 0 }"
                                    data-target="#slider_modal" :data-slide-to="index">
                                </li>
                            </ol>
                               <!-- <div
                                    v-if="modal_product.url_image"
                                    :style="`background-image: url(assets/images/${slide})`"
                                    class="bg-image"></div> -->
                            <div class="carousel-inner">
                                <div
                                    v-for="(slide, index) in modal_product.slides" :key="index" 
                                    class="carousel-item"
                                    :class="{ active: index == 0 }">
                                    <img 
                                        v-if="data_products.products_image_full && modal_product.url_image" 
                                        class="img-product"
                                        :src="require(`@/assets/images/${slide}`)" width="100%"  alt="">

                                    <div
                                        v-if="modal_product.url_image && !data_products.products_image_full"
                                        :style="`background-image: url(${require(`@/assets/images/${slide}`)})`"
                                        class="bg-image">
                                    </div>
                                    <!-- <img :src="require(`@/assets/images/${slide}`)" width="100%"  alt=""> -->
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#slider_modal" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only" >Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#slider_modal" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                        <h1 class="title">
                            <span>{{ modal_product.name }}</span>
                        </h1>
                        <p v-html="modal_product.description"
                            class="description">
                           <!-- // -->
                        </p>
                        <p  v-if="modal_product.price"
                            class="price">
                            $ {{ modal_product.price }}    
                        </p> 
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-close" data-dismiss="modal">Cerrar</button>
                        <a v-if="modal_product.url_pay" :href="modal_product.url_pay"
                            target="_blank"
                            class="btn btn-buy">
                            COMPRAR
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data_products'],
    data() {
        return {
            selected_types: [],
            filter_products: [],
            modal_product: []
        }
    },
    mounted() {
        this.filter_products = this.data_products.products;
        this.changeTypes();
    },
    methods: {
        changeTypes() {
            if (typeof this.current_tag != 'undefined') {
                this.selected_types = [];
                this.selected_types.push(this.current_tag);
            } else {
                this.selected_types = [];
            }
        },
        toggleProductModal(product) {
            this.modal_product = product;
            this.$refs.btn_show_product_modal.click();
        }
    },
    computed: {
        products() {
            return this.data_products.products;
        },
        current_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        $route (to, from) {
            this.changeTypes();
        },
        selected_types() {  
            let filter = [];
            for (const key in this.selected_types) { // Se recorren los filtros seleccionados ("oficina, mueble etc")
                for (const key_2 in this.products) { // Se recorre cada producto                              
                    for (const key_3 in this.products[key_2].tags) { // Dentro de cada producto se recorren los tags
                        if (this.selected_types[key] == this.products[key_2].tags[key_3]) { // Se comparan estos tags con los que seleccionó en usuario en el primer paso
                            filter.unshift(this.products[key_2]); // Si los tags coinciden, se agrega a la lista
                        }  
                    }
                }
            }

            if (this.selected_types.length == 0) { // Si se quitan todos los filtros, se muestran todos los productos. Esto se hace para que no se quede vacia la vista de productos
                this.filter_products = this.products;  
            } else {
                this.filter_products = filter;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.store-products {
    @extend %default-container-paddings;
    padding-bottom: 0px !important;

    .title-page {
        margin-bottom: 1.5rem;

        h1 { font-size: 2rem }

        @media (min-width: 1900px) {
            margin-bottom: 2rem;
            h1 { font-size: 2.7rem }
        }
    }

    .title-page-offer {
        .first {
            display: flex;
            align-items: center;
    
            .star {
                width: 30px;
                margin-right: 10px
            }
    
            margin-bottom: 28px;
            h1 { 
                font-size: 20px;
                margin-bottom: 0px;
                margin-top: 3px;
                font-weight: 600
            }
        }

        h4 {
            margin-bottom: 50px;
            font-size: 1.2rem;
            text-align: center;
        }
        
        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4 {
                margin-bottom: 26px;
            }
        }
        @media (min-width: 1200px) {
            .first {
                .star { width: 35px }
                h1 { font-size: 30px; margin-top: 5px }
            }
        }

        @media (min-width: 1280px) {
            margin-bottom: 2rem;

            h4 {
                font-size: 1.3rem;
                margin-bottom: 33px
            }
        }
        @media (min-width: 1900px) {
            .first { 
                .star { width: 40px }
                h1 { font-size: 35px }
            }
        }
    }

    .container-products {
        display: flex;
        flex-direction: column-reverse;

        // Left bar
        .left-bar {
            // Options
            .options {
                padding: 25px;
                margin-bottom: 3rem;
                background-color: #F2F2F2;

                .section {
                    .title { 
                        font-family: $SourceSansPro-SemiBold;
                        margin-bottom: 1.3rem;
                        border-bottom: 1px solid #565655;
                        padding-bottom: 0.8rem
                    }

                    ul {
                        list-style: none;
                        padding: 0px;

                        li {
                            align-items: center;  
                            padding: 5px 0px 5px 0px; 

                            .label-check {
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                
                                .input-check { 
                                    display: none;

                                    &:checked + .box-check {
                                        background-color: #565655
                                    }
                                }
                                .box-check {
                                    border: 1px solid #565655;
                                    width: 18px;
                                    height: 18px;
                                    margin-right: 10px
                                }
                            }
                        }
                    }
                }
            }

            // CONTACT ALL
            .contact {
                margin-bottom: 2rem;
                
                .data-contact {
                    text-align: center;
                    h1 { color: $main-red-1; font-size: 1.3rem; font-weight: 600; text-align: center; letter-spacing: 1px }
                    ul {
                        padding: 0px;
                        list-style: none;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        li {
                            font-size: 0.9rem;
                            margin: 5px;
                            margin-bottom: 5px;

                            a {
                                text-decoration: none;
                                font-size: 0.9rem;
                                color: #000000;
                                font-weight: 600;

                                &:hover{ opacity: 0.8 }
                            }
                        }
                    }
                }         
                button { @extend %generic-pico-button; }
            }
            // CONTACT OFFERS
            .contact_offers {
                margin-bottom: 2rem;
                text-align: center;

                .logo {
                    max-width: 180px;
                    margin-bottom: 2rem;
                }

                .contact-title {
                    margin-bottom: 2rem;
                    
                    h1 {
                        font-family: $SourceSansPro-Bold;
                        font-weight: 600;
                        font-size: 1.5rem;

                        span { color: $main-red-1 }
                    }
                    p {
                        font-weight: 600;
                        margin-bottom: 0px;
                        font-size: 0.9rem;
                        line-height: 1.3rem
                    }
                }

                .warranty {
                    margin-bottom: 1.5rem;
                    .image-warranty {
                        max-width: 90px;
                    }

                    h1, p {
                        font-weight: 600;
                        span { color: $main-red-1 }
                    }

                    h1 { font-size: 1.5rem }
                    
                    .payu {
                        width: 100%
                    }
                }

                .data-contact {
                    h1 { color: $main-red-1; font-size: 1.3rem; font-weight: 600 }
                    ul {
                        padding: 0px;
                        list-style: none;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        li {
                            font-size: 0.9rem;
                            margin: 5px;
                            margin-bottom: 5px;

                            a {
                                text-decoration: none;
                                font-size: 0.9rem;
                                color: #000000;
                                font-weight: 600;

                                &:hover{ opacity: 0.8 }
                            }
                        }
                    }
                }
            }

            @media (min-width: 1024px) {
                width: 30%
            }
            @media (min-width: 1200px) {
                max-width: 300px
            }
            @media (min-width: 1900px) {
                max-width: 350px
            }
        }
        // Products
        .products {
            .item-product {
                // border: 1px solid red;
                margin-bottom: 3.5rem;
                cursor: pointer;

                .bg-image {
                    height: 250px;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                    margin-bottom: 1.5rem;
                    @include transition(all 0.2s);
                }
                .image {
                    margin-bottom: 15px
                }
                .title {
                    font-size: 1.3rem;
                    @include transition(all 0.2s)   
                }
                &:hover {
                    .bg-image { opacity: .7 }
                    .title, .price {
                        color: $main-red-1;
                    }
                }

                @media (min-width: 760px) {
                    width: 40%;
                    margin: 0% 5% 5% 5%
                }

                @media (min-width: 1200px) {
                    width: 30%;
                    margin: 0% 1% 5% 1%
                }

                @media (min-width: 1900px) {
                    .bg-image { height: 300px }
                }
            }

            .item-product-offer {
                @media (min-width: 760px) {
                    width: 42% !important;
                    margin: 0% 0% 5% 5% !important
                }

                @media (min-width: 1200px) {
                    width: 45% !important;
                    .bg-image { height: 300px !important }
                    margin: 0% 1% 2% 1% !important
                }

                @media (min-width: 1900px) {
                    .bg-image { height: 380px !important }
                }
            }

            @media (min-width: 760px) {
                display: flex;
                flex-wrap: wrap;
                align-items: center
            } 

            @media (min-width: 1024px) {
                width: 68%
            }
            @media (min-width: 1200px) {
                width: 80%;
                padding-left: 30px
            }
        }

        @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}

#show_product_modal {
    .modal-body {
        padding: 0px;

        .bg-image {
            margin-bottom: 1.5rem;
            height: 250px; 
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 4px 4px 0px 0px;
        }
        .image {
            margin-bottom: 20px
        }

        .carousel-item {
            text-align: center;

            .img-product {  
                max-width: 500px;
                margin: 15px auto 15px auto;
            }
        }

        .title {
            font-family: $SourceSansPro-SemiBold;
            font-size: 1.7rem;
            text-align: center;
            margin: 0px 5px 1.3rem 5px;

            span {
                padding-bottom: 0.8rem;
                @media (min-width: 760px) {
                    border-bottom: 1px solid #565655;
                }
            }
        }
        .description, .price {
            padding: 10px 15px 10px 15px;
            margin-bottom: 0px;
            line-height: 1.3rem
        }

        .price {
            font-size: 1.2rem;
            letter-spacing: 1px;  
            text-align: right
        }

        @media (min-width: 760px) {
            .description, .price {
                padding: 15px 35px 15px 35px;
            }
            .bg-image { height: 350px }
        }
        @media (min-width: 1024px) {
            .bg-image { height: 450px }
        }
    }

    .modal-footer {
        .btn-close {
            margin-right: 8px;
            &:focus { box-shadow: 0px 0px 0px 0px }
        }
        .btn-buy {
            @extend %generic-pico-button;
            color: #FFFFFF;
            background-color: $main-red-1
        }
    }
}
</style>